import { type UserMenuItem } from "@/featuresPrincess/layout/types";
import { UserProfilePreview } from "./UserProfilePreview";
import { UserDropdownMenuNavigationItem } from "./UserDropdownMenuItem";
import Link from "next/link";
import { SheetClose } from "@princess/ui/sheet";
import { useGiftCollection } from "@/features/user/hooks/useGiftCollection";
import { MonthlyGiftCollectionSection } from "@/featuresPrincess/layout/userMenu/MonthlyGiftCollectionSection";
import { useState } from "react";
import { type Myself } from "@princess/common/user/types";

const ITEM_CLASSNAME = "rounded-md px-3 py-2 hover:bg-gray-100";

// This component is only used in BottomTab.tsx
export const UserFullScreenMenu = ({
  user,
  menuItems,
  closeMenu,
}: {
  user: Myself;
  menuItems: UserMenuItem[];
  closeMenu: () => void;
}) => {
  const { shouldShowGiftCollection } = useGiftCollection();
  const [hasJustCollectedGift, setHasJustCollectedGift] = useState(false);

  return (
    <div className="relative flex max-h-full flex-1 flex-col gap-3 bg-gray-50">
      <div className="space-y-3 bg-white px-5 py-6">
        <SheetClose className="w-full hover:opacity-70">
          <UserProfilePreview user={user} className="p-0" />
        </SheetClose>

        {(hasJustCollectedGift || shouldShowGiftCollection) && (
          <MonthlyGiftCollectionSection
            onCollectSuccess={() => setHasJustCollectedGift(true)}
          />
        )}
      </div>

      <div className="flex flex-1 flex-col gap-1 bg-white px-2 py-4">
        {menuItems.map((item) => {
          if (item.href) {
            return (
              <SheetClose key={item.title} asChild>
                <Link
                  href={item.href}
                  passHref
                  key={item.title}
                  className={ITEM_CLASSNAME}
                >
                  <UserDropdownMenuNavigationItem
                    title={item.title}
                    subtitle={
                      typeof item.subtitle === "function"
                        ? item.subtitle(closeMenu)
                        : item.subtitle
                    }
                    Icon={item.icon}
                  />
                </Link>
              </SheetClose>
            );
          }

          return (
            <SheetClose key={item.title} asChild>
              <div className={ITEM_CLASSNAME} onClick={item?.onClick}>
                <UserDropdownMenuNavigationItem
                  title={item.title}
                  subtitle={
                    typeof item.subtitle === "function"
                      ? item.subtitle(closeMenu)
                      : item.subtitle
                  }
                  Icon={item.icon}
                />
              </div>
            </SheetClose>
          );
        })}
      </div>
    </div>
  );
};
