import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import { useAuthToken } from "@/oldFeatures/auth/hooks/useAuthToken";
import { useApolloClient } from "@apollo/client";
import { type Myself } from "@princess/common/user/types";
import {
  UserStatusEnum,
  useMyselfV2Query,
  useMessagesQuery,
  useUserLastSeenMutation,
} from "@princess/graphql-codegen/graphql-hooks";
import * as Sentry from "@sentry/browser";
import { useQueryClient } from "@tanstack/react-query";
import cookie from "js-cookie";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInterval } from "react-use";

const { authHeaderKey } = getPublicConfig();

interface UserContext {
  user: Myself | null;
  token?: string | null;
  messageCount: number;
  loading: boolean;
  setToken: (token: string) => void;
  refetchUser: () => Promise<void>;
  /**
   * remove the authtoken in user browser and remove the user object as well
   */
  logout: () => Promise<void> | void;
}

const UserContext = createContext<UserContext | null>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleGraphqlError } = useErrorHandling();
  const [loading, setLoading] = useState(true);
  const apolloClient = useApolloClient();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { token, setToken, removeToken } = useAuthToken();

  // Skip query when no token.
  const { refetch: refetchMyself } = useMyselfV2Query({
    fetchPolicy: "network-only",
    skip: !token,
    onCompleted: (data) => {
      const user = data?.users[0];

      if (!user) return;

      Sentry.setUser(
        user && {
          id: user.userId,
          email: user.email ?? undefined,
          username: user.nickname ?? undefined,
        }, // will be null if there is no user
      );
    },
    onError: (error) => {
      handleGraphqlError({ error });
    },
  });

  const [user, setUser] = useState<Myself | null>(null);

  const refetchUser = useCallback(async () => {
    if (token) {
      const result = await refetchMyself();
      setUser(result?.data?.users[0] ?? null);
      cookie.set(authHeaderKey, token);
    } else {
      setUser(null);
      cookie.remove(authHeaderKey);
    }
    setLoading(false);
  }, [refetchMyself, token]);
  // If token exist, set interval to 1min, else use `null` to stop the interval
  useInterval(refetchUser, token ? 60000 : null);

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  // Do not use a state to store user.
  // Instead, if there is no token, directly return null for user
  // const user = (token && data?.users?.[0]) || null;

  // Last seen and messages count
  // Should not query or mutate if user has not completed profile
  const userCompleteProfile = user?.status === UserStatusEnum.ProfileCompleted;
  const [updateLastSeen] = useUserLastSeenMutation();
  const { data: messagesData, refetch: refetchMessagesData } = useMessagesQuery(
    {
      variables: { msgQueryInput: { unread: true } },
      skip: !userCompleteProfile,
    },
  );
  // Directly calculate messages count instead of storing in state
  const messageCount = messagesData?.messages?.messages.length || 0;

  // When user changed and user has complete profile,
  // fetch messages count and update last seen
  useEffect(() => {
    if (userCompleteProfile) {
      refetchMessagesData();
      updateLastSeen();
    }
  }, [refetchMessagesData, updateLastSeen, user, userCompleteProfile]);

  const logout = useCallback(async () => {
    queryClient.clear();
    removeToken();
    setUser(null);
    cookie.remove(authHeaderKey);
    await apolloClient.resetStore();
  }, [queryClient, removeToken, apolloClient, router]);

  return (
    <UserContext.Provider
      value={{
        user: user
          ? {
              ...user,
            }
          : null,
        messageCount,
        loading: loading,
        token,
        setToken,
        refetchUser,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context)
    throw new Error("useUserContext must be used within UserProvider");
  return context;
};
