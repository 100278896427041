import { Route } from "@/consts";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import FriendIcon from "public/assets/icons/friend.svg";
import SignOutIcon from "public/assets/icons/sign_out.svg";
import SupportIcon from "public/assets/icons/support.svg";
import CreditCardIcon from "public/assets/icons/credit_card.svg";
import CreditIcon from "public/assets/icons/credit_gold.svg";
import LanguageIcon from "public/assets/icons/language.svg";
import RefreshIcon from "public/assets/icons/refresh.svg";
import { CrownNewIcon } from "@/oldFeatures/common/components";
import { UserRoleEnum } from "@princess/graphql-codegen/graphql-hooks";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { appLanguages } from "@/consts/appLanguage";
import { useAppLanguage } from "@/featuresPrincess/common/hooks/useAppLanguage";
import { Button } from "@princess/ui/button";
import { useRouter } from "next/router";

export const useUserNavigationItems = () => {
  const { t } = useTranslation("common");
  const { user, logout } = useUserContext();
  const { currentLanguage, changeLanguage } = useAppLanguage();
  const router = useRouter();

  return useMemo(
    () => [
      {
        title: t("common:menu.friend"),
        icon: FriendIcon,
        href: Route.Friend,
      },
      {
        title: t("common:menu.credits"),
        icon: CreditIcon,
        href: Route.Recharge,
      },
      {
        title: t("common:menu.payment"),
        subtitle: (onClick: () => void) =>
          user?.role !== UserRoleEnum.Premium && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                router.push(Route.Premium);
                onClick();
              }}
              className="filled flex h-fit items-center gap-2 py-1 text-sm"
            >
              <CrownNewIcon className="h-4 w-4" />
              <span>{t("menu.premium_short")}</span>
            </Button>
          ),
        icon: CreditCardIcon,
        href: Route.Payment,
      },
      {
        title: t("support:title"),
        icon: SupportIcon,
        href: Route.Faq,
      },
      {
        title: appLanguages[currentLanguage].labelShort,
        subtitle: (
          <div className="flex gap-2">
            <span className="text-base font-normal text-gray-400">
              {appLanguages[currentLanguage === "en" ? "zh" : "en"].label}
            </span>
            <RefreshIcon className="h-6 w-6 text-gray-400" />
          </div>
        ),
        icon: LanguageIcon,
        onClick: () => changeLanguage(currentLanguage === "en" ? "zh" : "en"),
      },
      {
        title: t("common:menu.sign_out"),
        icon: SignOutIcon,
        onClick: logout,
      },
    ],
    [changeLanguage, currentLanguage, logout, router, t, user?.role],
  );
};
