import { InstallAppBadge } from "@/featuresPrincess/common/InstallAppBadge";
import { useTranslation } from "next-i18next";
import { Text, textVariant } from "@princess/ui/custom/text";
import Link from "next/link";
import { Route } from "@/consts";
import { useDomainContext } from "@/helpers/useDomainContext";
import { useFooterItems } from "@/featuresPrincess/layout/useFooterItems";

import { type ComponentProps, type PropsWithChildren } from "react";
import { cn } from "@princess/ui";
import { LanguageOptionDropdown } from "@/features/common/layout/header/LanguageOptionDropdown";

const Divider = () => {
  return <div className="h-5 w-[1px] bg-gray-400" />;
};

export const Footer = () => {
  const { t } = useTranslation("common");
  const { aboutUsItems, socialMediaItems, navigationItems } = useFooterItems();
  const { isMillionBB } = useDomainContext();

  return (
    <div className="z-50 w-full border-t border-t-neutral-200 bg-gray-100 bg-gray-50/50 px-4 py-5 sm:px-8 md:px-16 lg:px-24 xl:px-40">
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col items-center gap-3.5 md:flex-row md:justify-between md:align-middle">
          <div className="flex items-center gap-5">
            <Text variant="dark" size="lg" weight="medium">
              {t("footer.get_app")}
            </Text>
            <div className="flex gap-2.5">
              <InstallAppBadge os="ios" variant="small" />
              <InstallAppBadge os="android" variant="small" />
            </div>

            <Divider />

            <LanguageOptionDropdown
              classes={{
                trigger:
                  "justify-center rounded-full border border-gray-300 bg-white px-2 py-1",
                triggerIcon: "h-5 w-5 text-gray-500",
                dropdownIcon: "h-1 w-1.5 text-gray-500",
                chipText: "font-medium text-gray-500",
              }}
              labelType="full"
            />
          </div>

          <div className="grid grid-flow-col gap-12">
            <FooterNavigationSection title={t("footer.link_product")}>
              {aboutUsItems.map(({ label, path }) => (
                <FooterNavigationItem key={label} href={path}>
                  {label}
                </FooterNavigationItem>
              ))}
            </FooterNavigationSection>
            <FooterNavigationSection title={t("footer.link_follow")}>
              {socialMediaItems.map(({ label, path }) => (
                <FooterNavigationItem key={label} href={path} target="_blank">
                  {label}
                </FooterNavigationItem>
              ))}
            </FooterNavigationSection>
            <FooterNavigationSection>
              {navigationItems.map(({ label, path }) => (
                <FooterNavigationItem key={label} href={path}>
                  {label}
                </FooterNavigationItem>
              ))}
            </FooterNavigationSection>
          </div>
        </div>

        <div className="h-[1px] w-full bg-gray-200" />

        <div className="flex flex-col items-center gap-2 sm:flex-row sm:justify-between">
          <div className="flex gap-2.5">
            <Link href={Route.Tnc} rel="noreferrer noopener" passHref>
              <Text
                variant="light"
                size="sm"
                weight="medium"
                className="hover:text-gray-700"
              >
                {t("footer.tnc")}
              </Text>
            </Link>

            <Divider />

            <Link href={Route.Privacy} rel="noreferrer noopener" passHref>
              <Text
                variant="light"
                size="sm"
                weight="medium"
                className="hover:text-gray-700"
              >
                {t("footer.privacy")}
              </Text>
            </Link>
          </div>
          <Text size="sm">
            {t("footer.copyright", {
              name: isMillionBB ? "MillionBB" : "Princess Dating",
              year: new Date().getFullYear(),
            })}
          </Text>
        </div>
      </div>
    </div>
  );
};

type FooterNavigationSectionProps = PropsWithChildren<{
  title?: string;
}>;
const FooterNavigationSection = ({
  children,
  title,
}: FooterNavigationSectionProps) => {
  return (
    <div className="flex flex-col gap-1">
      {title && (
        <Text variant="dark" size="sm" weight="medium">
          {title}
        </Text>
      )}
      {children}
    </div>
  );
};

type FooterNavigationItemProps = ComponentProps<typeof Link>;
const FooterNavigationItem = ({
  children,
  ...props
}: FooterNavigationItemProps) => {
  return (
    <Link
      className={cn(
        textVariant({
          variant: "light",
          size: "sm",
        }),
        "hover:text-gray-700",
      )}
      {...props}
    >
      {children}
    </Link>
  );
};
