import clsx from "clsx";
import React from "react";
import { type InputProps } from "@princess/ui/input";

const InputGroup: React.FC<{ children: React.ReactNode }> = (props) => {
  return <div className="relative">{props.children}</div>;
};

const LeftInputExtension = React.memo(
  (props: { children: React.ReactNode }) => {
    return (
      <div className="absolute left-0 top-1/2 -translate-y-1/2 transform">
        {props.children}
      </div>
    );
  },
);
LeftInputExtension.displayName = "LeftInputExtension";

const RightInputExtension = React.memo(
  (props: { children: React.ReactNode }) => {
    return (
      <div className="absolute right-0 top-1/2 -translate-y-1/2 transform">
        {props.children}
      </div>
    );
  },
);
RightInputExtension.displayName = "RightInputExtension";

const InputExtension = {
  Left: LeftInputExtension,
  Right: RightInputExtension,
};

const InputItem = {
  Left: (props: { children: React.ReactNode; masked?: boolean }) => {
    const className = clsx("flex h-10 w-10 items-center justify-center", {
      "border-input rounded-l-md bg-gray-200": props.masked,
    });
    return (
      <InputExtension.Left>
        <div className={className}>{props.children}</div>
      </InputExtension.Left>
    );
  },
  Right: (props: {
    children: React.ReactNode;
    masked?: boolean;
    className?: string;
  }) => {
    const className = clsx(props.className);
    return (
      <InputExtension.Right>
        <div className={className}>{props.children}</div>
      </InputExtension.Right>
    );
  },
};

const InputControl = {
  Left: (props: {
    children: React.ReactElement<InputProps>;
    className?: string;
    masked?: boolean;
  }) => {
    return React.cloneElement(props.children, {
      className: clsx(props.masked ? "pr-12" : "pr-10", props.className),
    });
  },
  Middle: (props: {
    children: React.ReactElement<InputProps>;
    className?: string;
    masked?: boolean;
  }) => {
    return React.cloneElement(props.children, {
      className: clsx(props.masked ? "px-12" : "px-10", props.className),
    });
  },
  Right: (props: {
    children: React.ReactElement<InputProps>;
    className?: string;
    masked?: boolean;
  }) => {
    return React.cloneElement(props.children, {
      className: clsx(props.masked ? "pl-12" : "pl-10", props.className),
    });
  },
};

export { InputGroup, InputExtension, InputItem, InputControl };
