import React from "react";
import * as z from "zod";
import {
  useForm,
  FormProvider,
  type ControllerRenderProps,
  type FieldValues,
  type Control,
  type UseFormReturn,
  type UseFormReset,
  type UseFormWatch,
  type UseFormSetValue,
  type FieldValue,
  type UseFormResetField,
  useFormContext,
  type Mode,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,
} from "@princess/ui/form";

import { RadioGroup, RadioGroupItem } from "@princess/ui/radioGroup";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@princess/ui/select";
import { Input } from "@princess/ui/input";
import { Textarea } from "@princess/ui/textarea";
import { TagSelect } from "@/modules/input/tag/TagSelect";
import { DatePicker } from "@/modules/input/date-picker/DatePicker";
import {
  InputControl,
  InputGroup,
  InputItem,
} from "../input/extensions/input-extensions";
import { Button, type ButtonProps } from "@princess/ui/button";
import { Checkbox } from "@princess/ui/checkbox";
import { cn } from "@princess/ui";
import { Spinner } from "@/modules/form-builders/Spinner";
import { InputLength, getLengthByMethod } from "@/modules/input/InputLength";
import { CheckboxGroup } from "@/modules/input/checkbox/CheckboxGroup";
import {
  SelectionGrid,
  type Props as SelectionGridProps,
} from "@/modules/input/selection-grid/SelectionGrid";
import { twc } from "react-twc";
import {
  Disclaimer,
  type DisclaimerProps,
} from "@/modules/disclaimer/Disclaimer";

export const FormBuilderLabel = twc(
  FormLabel,
)`text-foreground text-sm font-normal`;

// @todo use this in onSubmit
/*
 * Infer form data type from form fields
 * eg. const field = { email: { ...otherFormFieldDefinitions, validation: z.string() } }
 *     type FormData = ExtractFormDataType<typeof field>;
 *     --> FormData will be of type { email: string }
 */
export type ExtractFormDataType<T> = {
  [K in keyof T]: T[K] extends { validation: z.ZodEffects<z.ZodType, infer U> }
    ? U
    : T[K] extends { validation: z.ZodType<infer U> }
      ? U
      : any;
};

type FormFieldType = FormFieldDefinition<any>["type"];

type CommonFieldDefinition<T extends string> = {
  label?: string;
  description?: string;
  onDescriptionChange?: (value: FieldValue<FieldValues>) => React.ReactNode;
  required?: boolean;
  mandatory?: boolean;
  validation?: z.ZodType<any>;
  component?: React.ComponentType<any>;
  readonly?: boolean;
  hideLabel?: boolean;

  watch?: T;
  /** @TODO map the value type */
  onWatchChange?: (value: FieldValues) => FieldValue<FieldValues>;
  onDisplayChange?: (value: FieldValues) => boolean;
  onGenerate?: () => T;
};

type CommonTextFieldDefinition<T extends string> = CommonFieldDefinition<T> & {
  /**
   * HTML input fields
   */
  defaultValue?: string;
  placeholder?: string;
  iconMasked?: boolean;
  icon?: React.ReactNode;
};

type FormFieldDefinition<T extends string> =
  | {
      type: "section";
      label: string;
    }
  | {
      type: "label";
      label: string;
    }
  | {
      type: "divider";
    }
  | {
      type: "static";
      label: string;
      value: string;
    }
  | {
      type: "disclaimer";
      renderMessage: DisclaimerProps["renderMessage"];
      mandatory?: boolean;
    }
  | ({
      type: "password";
    } & CommonTextFieldDefinition<T>)
  | ({
      type: "text";

      /**
       * HTML textarea fields
       */
      rows?: number;
      /**
       * Word count fieds
       */
      maxLength?: number;
      countingMethod?: "normal" | "unicode_double";

      /**
       * Advanced fields
       */
      onSearch?: (term: string) => Promise<void> | void;
    } & CommonTextFieldDefinition<T>)
  | ({
      type: "name" | "email" | "phone" | "url";
    } & CommonTextFieldDefinition<T>)
  | ({
      type: "checkbox";
      options: Array<{ label: string; value: string }>;
      defaultValue?: Array<string>;
    } & CommonFieldDefinition<T>)
  | ({
      type: "checkboxGroup";
      label: string;
      options: Array<{ label: string; value: string }>;
      defaultValue?: Array<string>;
      hideLabel?: boolean;
    } & CommonFieldDefinition<T>)
  | ({
      type: "radio";
      options: Array<{ label: string; value: string }>;
      defaultValue?: string;
      direction?: "row" | "column";
    } & CommonFieldDefinition<T>)
  | ({
      type: "selectionGrid";
      options: SelectionGridProps["options"];
      defaultValue?: string;
      classNames?: SelectionGridProps["classNames"];
      itemsPerRow?: SelectionGridProps["itemsPerRow"];
    } & CommonFieldDefinition<T>)
  | ({
      type: "select";
      options: Array<{ label: string; value: string }>;
      placeholder?: string;
      defaultValue?: string;
    } & CommonFieldDefinition<T>)
  | ({
      type: "date" | "birthday";
      placeholder?: string;
      defaultValue?: Date;
      range?: [number, number];
    } & CommonFieldDefinition<T>)
  | ({
      type: "tag";
      options: Array<{ label: string; value: string }>;
      placeholder?: string;
      defaultValue?: Array<{ label: string; value: string }>;
      isCreatable?: boolean;
      maxLength?: number;
      onSearch?: (
        value: string,
      ) => Promise<Array<{ label: string; value: string }>>;
    } & CommonFieldDefinition<T>);

type LayoutItem<T extends string> = T | [T, T];

type FormComponents = {
  // Optional fields
  input?: React.ComponentType<any>;
  textarea?: React.ComponentType<any>;
  button?: React.ComponentType<any>;
  resetButton?: React.ComponentType<any>;
  subActionButton?: React.ComponentType<any>;
  sectionHeader?: React.ComponentType<any>;
  date?: React.ComponentType<any>;
};

type Props<T extends string> = {
  mode?: Mode;
  layout: Array<LayoutItem<T>>;
  fields: Record<T, FormFieldDefinition<T>>;

  // Remarks: optional
  testMode?: boolean;
  id?: string;
  url?: string;
  onSubmit?: OnSubmitProps<T>["onSubmit"];
  onSubmitted?: (data: { data: any; error: any }) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  components?: FormComponents;
  additionalPayload?: Record<string, any>;
  actionButtonLabel?: string;
  allowReset?: boolean;
  submitOnPristine?: boolean; //added to override action buttons being disabled when the form is not dirty, when set to true, the action buttons are disabled only when the form state is loading
  submitOnValid?: boolean;
  submitOnValidAndDirty?: boolean;
  onSubActionButtonClick?: (data: ExtractFormDataType<any>) => void;
  subActionButtonLabel?: string;
  schemaExtension?: (schema: z.ZodType<any>) => z.ZodType<any>;
  footer?: React.ReactNode;
  renderActionRow?: (props: { children: React.ReactNode }) => React.ReactNode;
};

const isNotNullOrUndefined = <T extends unknown>(
  value: T,
): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

const DefaultComponents = {
  input: (props: React.InputHTMLAttributes<HTMLInputElement>) => (
    <Input {...props} />
  ),
  textarea: (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => (
    <Textarea {...props} />
  ),
  button: (props: ButtonProps) => <Button {...props} />,
  section: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h3 {...props} />
  ),
  static: (props: React.HTMLAttributes<HTMLSpanElement>) => <div {...props} />,
};

const buildFormFieldPresetSchema = <T extends string>(): Record<
  Exclude<
    FormFieldType,
    "section" | "label" | "static" | "divider" | "disclaimer"
  >,
  (field: CommonFieldDefinition<T>) => z.ZodType<any>
> => {
  // references (optional fields validation): https://zod.dev/?id=unions
  const buildOptionalSchema = (schema: z.ZodType<any>) =>
    z.union([schema.optional(), z.literal("")]);

  return {
    text: ({ required }) => {
      const schema = z.string();

      return required ? schema.min(1) : buildOptionalSchema(schema);
    },
    name: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(3, {
            message: "Name cannot be empty or less than 3 characters",
          })
        : buildOptionalSchema(schema);
    },
    email: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.email({
            message: "Invalid email address",
          })
        : buildOptionalSchema(schema);
    },
    phone: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(3, {
            message: "phone must not be empty",
          })
        : buildOptionalSchema(schema);
    },
    url: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.url({ message: "Invalid URL" })
        : buildOptionalSchema(schema);
    },
    radio: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(1, { message: "Please select an option" })
        : buildOptionalSchema(schema);
    },
    checkbox: ({ required }) => {
      const schema = z.string().array();

      return required
        ? schema.min(1, { message: "Please select an option" })
        : buildOptionalSchema(schema);
    },
    checkboxGroup: ({ required }) => {
      const schema = z.string().array();

      return required
        ? schema.min(1, { message: "Please select an option" })
        : buildOptionalSchema(schema);
    },
    selectionGrid: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(1, { message: "Please select an option" })
        : buildOptionalSchema(schema);
    },
    select: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(1, { message: "Please select an option" })
        : buildOptionalSchema(schema);
    },
    tag: ({ required }) => {
      const schema = z.array(
        z.object({
          value: z.string(),
          label: z.string(),
        }),
      );

      return required
        ? schema.min(1, { message: "Please select an tag" })
        : buildOptionalSchema(schema);
    },
    date: ({ required }) => {
      const schema = z.date();

      return required ? schema : buildOptionalSchema(schema);
    },
    birthday: ({ required }) => {
      const schema = z.date();

      return required ? schema : buildOptionalSchema(schema);
    },
    password: ({ required }) => {
      const schema = z.string();

      return required
        ? schema.min(8, {
            message: "Password cannot be empty or less than 8 characters",
          })
        : buildOptionalSchema(schema);
    },
  };
};

const getDefaultValueFallback = (fieldType: FormFieldType) => {
  switch (fieldType) {
    case "date":
      return undefined;
    case "birthday":
      return undefined;
    case "checkbox":
    case "checkboxGroup":
    case "selectionGrid":
      return [];
    case "disclaimer":
      return false;
    default:
      return "";
  }
};

const getDefaultPlaceholderFallback = (fieldType: FormFieldType) => {
  switch (fieldType) {
    case "name":
      return "John Doe";
    case "email":
      return "example@mail.com";
    case "url":
      return "https://yoururl.com";
    case "birthday":
      return "Date of Birth";
    default:
      return undefined;
  }
};

const useFormSchema = <T extends string>(
  fields: Record<T, FormFieldDefinition<T>>,
  extension?: (schema: z.ZodType<any>) => z.ZodType<any>,
) => {
  return React.useMemo(() => {
    const allFieldNames = Object.keys(fields) as Array<T>;

    const fieldValidationEntries = allFieldNames
      .map((fieldName) => {
        const field = fields[fieldName];

        if (
          field.type === "section" ||
          field.type === "label" ||
          field.type === "static" ||
          field.type === "divider" ||
          field.type === "disclaimer"
        ) {
          return null;
        }

        const formFieldPresetSchema = buildFormFieldPresetSchema();
        const validationFallback = formFieldPresetSchema[field.type](field);
        return [fieldName, field.validation ?? validationFallback] as const;
      })
      .filter(isNotNullOrUndefined);

    const schema = z.object(Object.fromEntries(fieldValidationEntries));

    return extension ? extension(schema) : schema;
  }, [fields, extension]);
};

const useFormDefaultValues = <T extends string>(
  fields: Record<T, FormFieldDefinition<T>>,
) => {
  return React.useMemo(() => {
    const allFieldNames = Object.keys(fields) as Array<T>;

    const fieldDefaultValueEntries = allFieldNames
      .map((fieldName) => {
        const field = fields[fieldName];

        if (
          field.type === "section" ||
          field.type === "label" ||
          field.type === "static" ||
          field.type === "divider"
        ) {
          return null;
        }

        if (field.type === "disclaimer") {
          return [fieldName, false] as const;
        }

        return [
          fieldName,
          field.defaultValue ?? getDefaultValueFallback(field.type),
        ] as const;
      })
      .filter(isNotNullOrUndefined)
      .filter(([, defaultValue]) => isNotNullOrUndefined(defaultValue));
    return Object.fromEntries(fieldDefaultValueEntries);
  }, [fields]);
};

const useFormResetWhenDefaultValuesChange = (
  form: UseFormReturn,
  defaultValues: FieldValues,
) => {
  return React.useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
};

const useOnDirtyChange = (
  form: UseFormReturn,
  onDirtyChange?: (isDirty: boolean) => void,
) => {
  React.useEffect(() => {
    if (!onDirtyChange) {
      return;
    }
    onDirtyChange(form.formState.isDirty);
  }, [form.formState.isDirty, onDirtyChange]);
};

const useReactHookForm = <T extends string>(
  mode: Mode = "onSubmit",
  fields: Record<T, FormFieldDefinition<T>>,
  schemaExtension?: (schema: z.ZodType<any>) => z.ZodType<any>,
) => {
  const formSchema = useFormSchema(fields, schemaExtension);
  const defaultValues = useFormDefaultValues(fields);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  useFormResetWhenDefaultValuesChange(form, defaultValues);

  return form;
};

type OnSubmitProps<T extends string> = {
  onSubmit?: (
    data: ExtractFormDataType<any>,
    helper: {
      onReset: UseFormReset<FieldValues>;
    },
  ) => Promise<void> | void;
  onSubmitted?: (data: { data: any; error: any }) => void;
  reset: UseFormReset<FieldValues>;
  url?: string;
  additionalPayload?: Record<string, any>;
};

// Remarks: currently only either onSubmit or onSubmitted is supported
const createOnSubmit =
  <T extends string>(props: OnSubmitProps<T>) =>
  async (data: FieldValues) => {
    const typedData = data as Record<T, any>;

    if (props.onSubmit) {
      return props.onSubmit(typedData, {
        onReset: props.reset,
      });
    }

    if (!props.url) {
      console.warn(`No 'url' props provided, skipping submit.`);
      return;
    }

    if (!props.onSubmitted) {
      console.warn(`No 'onSubmitted' props provided, skipping submit.`);
      return;
    }

    try {
      const response = await fetch(props.url, {
        method: "POST",
        body: JSON.stringify({
          ...typedData,
          ...(props.additionalPayload ?? {}),
        }),
      }).then((res) => {
        if (!res.ok || res.status !== 200) {
          return res.json().then((body) => {
            throw new Error(`${body.name} - ${body.message}`);
          });
        }

        return res.json();
      });

      await props.onSubmitted({ data: response, error: null });
    } catch (e) {
      await props.onSubmitted({ data: null, error: e });
    }
  };

const useWatchFields = <T extends string>(
  fields: Record<T, FormFieldDefinition<T>>,
  dirtyFields: FieldValues,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  resetField: UseFormResetField<FieldValues>,
  hiddenFields: Array<T>,
  setHiddenFields: (hiddenFields: Array<T>) => void,
) => {
  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      const relatedFieldEntries = (
        Object.entries(fields) as Array<[T, FormFieldDefinition<T>]>
      ).filter(([, field]) => {
        if (
          field.type === "section" ||
          field.type === "label" ||
          field.type === "static" ||
          field.type === "divider" ||
          field.type === "disclaimer"
        ) {
          return null;
        }

        return field.watch === name;
      });

      relatedFieldEntries.forEach(([fieldName, field]) => {
        if ("onWatchChange" in field) {
          const expectedChange = field.onWatchChange?.(value);

          if (!isNotNullOrUndefined(expectedChange)) {
            resetField(fieldName);
            return;
          }

          const isFieldDirty =
            fieldName in dirtyFields && dirtyFields[fieldName];

          if (isFieldDirty) {
            console.warn(
              `Field ${fieldName} is dirty, skipping watch change update.`,
            );
          }

          if (isNotNullOrUndefined(expectedChange) && !isFieldDirty) {
            setValue(fieldName, expectedChange);
          }
        }

        if ("onDisplayChange" in field) {
          const shouldDisplay = field.onDisplayChange?.(value);

          if (!shouldDisplay) {
            setHiddenFields([...hiddenFields, fieldName]);
            return;
          }

          const isFieldDirty =
            fieldName in dirtyFields && dirtyFields[fieldName];

          if (isFieldDirty) {
            console.warn(
              `Field ${fieldName} is dirty, skipping watch change update.`,
            );
          }

          setHiddenFields(
            hiddenFields.filter((hiddenField) => hiddenField !== fieldName),
          );
        }
      });
    });
    return () => subscription.unsubscribe();
  }, [fields, dirtyFields, watch, setValue]);
};

const useHiddenFields = <T extends string>(
  form: UseFormReturn,
  fields: Record<T, FormFieldDefinition<T>>,
) => {
  const initialHiddenFields = (
    Object.entries(fields) as Array<[T, FormFieldDefinition<T>]>
  )
    .filter(([, field]) => {
      if ("onDisplayChange" in field) {
        return !field.onDisplayChange?.(form.getValues());
      }

      return false;
    })
    .map((entry) => entry[0]);

  return React.useState<Array<T>>(initialHiddenFields);
};

export const FormBuilder = <T extends string>(props: Props<T>) => {
  const form = useReactHookForm(
    props.mode,
    props.fields,
    props.schemaExtension,
  );
  const [hiddenFields, setHiddenFields] = useHiddenFields(form, props.fields);

  useOnDirtyChange(form, props.onDirtyChange);

  useWatchFields(
    props.fields,
    form.formState.dirtyFields,
    form.watch,
    form.setValue,
    form.resetField,
    hiddenFields,
    setHiddenFields,
  );

  const onGenerateFields = () => {
    const generatedFieldsEntries = (
      Object.entries(props.fields) as Array<[T, FormFieldDefinition<T>]>
    )
      .map(([fieldName, field]) => {
        if ("onGenerate" in field) {
          return [fieldName, field.onGenerate?.()];
        }

        return [fieldName, undefined];
      })
      .filter(([, value]) => !!value);

    const values = Object.fromEntries(generatedFieldsEntries);
    form.reset(values);
  };

  const onReset = form.reset;

  const onSubmit = createOnSubmit({
    onSubmit: props.onSubmit,
    onSubmitted: props.onSubmitted,
    reset: onReset,
    url: props.url,
    additionalPayload: props.additionalPayload,
  });

  const isMandatoryFieldsFilled = (
    Object.entries(props.fields) as Array<[T, FormFieldDefinition<T>]>
  ).every(([fieldName, field]) => {
    if ("mandatory" in field && field.mandatory) {
      const value = form.getValues(fieldName);
      return !!value;
    }

    return true;
  });

  const isFormLoading = form.formState.isSubmitting || form.formState.isLoading;

  const getAllowSubmit = () => {
    if (isFormLoading) {
      return false;
    }

    if (!isMandatoryFieldsFilled) {
      return false;
    }

    const {
      submitOnValid = false,
      submitOnPristine = false,
      submitOnValidAndDirty = false,
    } = props;

    if (submitOnValid) {
      return form.formState.isValid;
    }

    if (submitOnPristine) {
      return true;
    }

    if (submitOnValidAndDirty) {
      return form.formState.isValid && form.formState.isDirty;
    }

    return form.formState.isDirty;
  };

  return (
    <FormProvider {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        {props.layout.map((layoutItem, layoutIndex) => {
          const renderField = (fieldKey: T) => {
            const fieldItem = props.fields[fieldKey];

            if (!fieldItem) {
              throw new Error(`Field ${layoutItem} not found.`);
            }

            if (hiddenFields.includes(fieldKey)) {
              return null;
            }

            return (
              <div key={fieldKey}>
                <FormField
                  control={form.control}
                  name={fieldKey}
                  render={({ field }) => {
                    return (
                      <FormItem className="space-y-1">
                        {fieldItem.type !== "section" &&
                          fieldItem.type !== "label" &&
                          fieldItem.type !== "static" &&
                          fieldItem.type !== "divider" &&
                          fieldItem.type !== "disclaimer" &&
                          !fieldItem.hideLabel && (
                            <div className="flex items-center justify-between">
                              <FormBuilderLabel>
                                {fieldItem.label}
                                <span className="text-red-500">
                                  {fieldItem.required && " *"}
                                </span>
                              </FormBuilderLabel>

                              {fieldItem.type === "text" &&
                                fieldItem.maxLength && (
                                  <InputLength
                                    inputValue={field.value}
                                    maxLength={fieldItem.maxLength}
                                    countingMethod={fieldItem.countingMethod}
                                  />
                                )}
                            </div>
                          )}

                        {"description" in fieldItem && (
                          <FormMessage className="text-sm text-gray-500">
                            {fieldItem.description}
                          </FormMessage>
                        )}

                        {"onDescriptionChange" in fieldItem &&
                          fieldItem.onDescriptionChange &&
                          field.value.length > 0 && (
                            <FormMessage className="text-sm text-gray-500">
                              {fieldItem.onDescriptionChange(field.value)}
                            </FormMessage>
                          )}
                        <FormFieldRenderer
                          formField={field}
                          formControl={form.control}
                          formFieldDefinition={fieldItem}
                          isFirst={layoutIndex === 0}
                          components={props.components}
                        />
                        <FormMessage className="text-sm font-normal text-red-600" />
                      </FormItem>
                    );
                  }}
                />
              </div>
            );
          };

          if (Array.isArray(layoutItem)) {
            const layoutItems = layoutItem;

            return (
              <div
                key={`form-layoutItems-${layoutIndex}`}
                className="grid-cols-2 gap-4 space-y-4 md:grid md:space-y-0"
              >
                {layoutItems.map((field) => (
                  <React.Fragment key={field}>
                    {renderField(field)}
                  </React.Fragment>
                ))}
              </div>
            );
          }

          return renderField(layoutItem);
        })}

        {props.footer ? <div className="my-4">{props.footer}</div> : null}

        <FormActionBar<T>
          testMode={props.testMode}
          onGenerateFields={onGenerateFields}
          allowReset={props.allowReset}
          actionButtonLabel={props.actionButtonLabel}
          components={props.components}
          onSubActionButtonClick={props.onSubActionButtonClick}
          subActionButtonLabel={props.subActionButtonLabel}
          loading={isFormLoading}
          disabled={!getAllowSubmit()}
          onReset={onReset}
          renderActionRow={props.renderActionRow}
        />
      </form>
    </FormProvider>
  );
};

type FormActionBarProps<T extends string> = Pick<
  Props<T>,
  | "testMode"
  | "allowReset"
  | "actionButtonLabel"
  | "components"
  | "onSubActionButtonClick"
  | "subActionButtonLabel"
> & {
  loading: boolean;
  disabled: boolean;
  onReset: UseFormReset<FieldValues>;
  onGenerateFields: () => void;
} & {
  renderActionRow?: Props<T>["renderActionRow"];
};

const FormActionBar = <T extends string>(props: FormActionBarProps<T>) => {
  const { getValues } = useFormContext();

  const FormButton = props.components?.button ?? DefaultComponents["button"];
  const FormSubActionButton =
    props.components?.subActionButton ??
    props.components?.button ??
    DefaultComponents["button"];
  const FormResetButton =
    props.components?.resetButton ??
    props.components?.button ??
    DefaultComponents["button"];
  const FormFieldsGenerateButton =
    props.components?.button ?? DefaultComponents["button"];

  const content = (
    <>
      {props.testMode && (
        <FormFieldsGenerateButton
          variant="ghost"
          onClick={props.onGenerateFields}
        >
          Generate
        </FormFieldsGenerateButton>
      )}
      {props.allowReset && (
        <FormResetButton
          type="reset"
          onClick={() => props.onReset({})}
          disabled={props.disabled}
        >
          Reset
        </FormResetButton>
      )}
      {props.onSubActionButtonClick && (
        <FormSubActionButton
          onClick={() => props.onSubActionButtonClick?.(getValues())}
          type="button"
          variant="destructive"
        >
          {props.subActionButtonLabel ?? "Cancel"}
        </FormSubActionButton>
      )}
      <FormButton
        type="submit"
        disabled={props.disabled}
        className="flex items-center gap-2 px-5"
      >
        {props.loading && <Spinner size="small" />}
        {props.loading && !props.actionButtonLabel
          ? "Submitting..."
          : props.actionButtonLabel ?? "Submit"}
      </FormButton>
    </>
  );

  if (props.renderActionRow) {
    return props.renderActionRow({ children: content });
  }

  return (
    <div style={{ marginTop: "1.5em" }} className="flex justify-end gap-2">
      {content}
    </div>
  );
};

type FormFieldRendererProps<T extends string> = {
  formField: ControllerRenderProps<FieldValues, any>;
  formControl: Control<FieldValues>;
  formFieldDefinition: FormFieldDefinition<T>;
  isFirst: boolean;
  components?: FormComponents;
};

const FormFieldRenderer = <T extends string>(
  props: FormFieldRendererProps<T>,
) => {
  const { formField, formFieldDefinition, components } = props;
  const { error } = useFormField();

  const FormSectionHeader =
    components?.sectionHeader ?? DefaultComponents["section"];

  if (formFieldDefinition.type === "section") {
    return (
      <FormSectionHeader className={!props.isFirst ? "mt-8" : ""}>
        {formFieldDefinition.label}
      </FormSectionHeader>
    );
  }

  if (formFieldDefinition.type === "label") {
    return (
      <FormItem>
        <FormBuilderLabel>{formFieldDefinition.label}</FormBuilderLabel>
      </FormItem>
    );
  }

  if (formFieldDefinition.type === "static") {
    return (
      <FormItem className="space-y-1">
        <FormBuilderLabel>{formFieldDefinition.label}</FormBuilderLabel>
        <div className="py-2 text-sm">{formFieldDefinition.value}</div>
      </FormItem>
    );
  }

  if (formFieldDefinition.type === "divider") {
    return <hr className="my-8 border-gray-200 dark:border-gray-800" />;
  }

  if (formFieldDefinition.type === "disclaimer") {
    return (
      <FormItem className="py-2">
        <Disclaimer
          value={formField.value}
          onChange={formField.onChange}
          renderMessage={formFieldDefinition.renderMessage}
        />
      </FormItem>
    );
  }

  const FormInput =
    formFieldDefinition.component ??
    components?.input ??
    DefaultComponents["input"];
  const FormTextarea = components?.textarea ?? DefaultComponents["textarea"];

  if (formFieldDefinition.type === "checkbox") {
    return (
      <div className="flex flex-wrap space-x-7">
        {formFieldDefinition.options.map((option) => (
          <FormField
            key={option.value}
            control={props.formControl}
            name="items"
            render={() => {
              return (
                <FormItem
                  key={option.value}
                  className="flex flex-row items-start space-x-3 space-y-0"
                >
                  <FormControl>
                    <Checkbox
                      className="border-gray-400"
                      checked={formField.value?.includes(option.value)}
                      onCheckedChange={(checked) => {
                        return checked
                          ? formField.onChange([
                              ...(formField.value ?? []),
                              option.value,
                            ])
                          : formField.onChange(
                              formField.value?.filter(
                                (value: string) => value !== option.value,
                              ),
                            );
                      }}
                      disabled={
                        formFieldDefinition.readonly ? true : formField.disabled
                      }
                    />
                  </FormControl>
                  <FormBuilderLabel>{option.label}</FormBuilderLabel>
                </FormItem>
              );
            }}
          />
        ))}
      </div>
    );
  }

  if (formFieldDefinition.type === "checkboxGroup") {
    return (
      <div className="flex flex-wrap space-x-7">
        <FormField
          key={`checkboxGroup-${formFieldDefinition.label}`}
          control={props.formControl}
          name="items"
          render={() => {
            return (
              <FormItem
                key={`checkboxGroup-${formFieldDefinition.label}-item`}
                className="flex flex-row items-start space-x-3 space-y-0"
              >
                <FormControl>
                  <CheckboxGroup
                    className="border-gray-400"
                    label={formFieldDefinition.label}
                    options={formFieldDefinition.options.map((option) => ({
                      ...option,
                      checked: formField.value?.includes(option.value),
                    }))}
                    onChange={(updatedOptions) => {
                      return formField.onChange(updatedOptions);
                    }}
                    disabled={
                      formFieldDefinition.readonly ? true : formField.disabled
                    }
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />
      </div>
    );
  }

  if (formFieldDefinition.type === "radio") {
    return (
      <FormControl>
        <RadioGroup
          onValueChange={formField.onChange}
          value={formField.value}
          className={cn(
            formFieldDefinition.direction === "column"
              ? "flex flex-col space-y-1"
              : "flex flex-wrap space-x-7",
          )}
          disabled={formFieldDefinition.readonly ? true : formField.disabled}
        >
          {formFieldDefinition.options.map((option) => (
            <FormItem
              key={option.value}
              className="my-1 flex items-center space-x-3 space-y-0"
            >
              <FormControl>
                <RadioGroupItem
                  value={option.value}
                  className="active:border-primary border-gray-400"
                />
              </FormControl>
              <FormBuilderLabel>{option.label}</FormBuilderLabel>
            </FormItem>
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (formFieldDefinition.type === "selectionGrid") {
    return (
      <FormControl>
        <SelectionGrid
          className="grid grid-cols-2 gap-4"
          classNames={formFieldDefinition.classNames}
          options={formFieldDefinition.options}
          value={formField.value}
          onChange={formField.onChange}
          disabled={formFieldDefinition.readonly ? true : formField.disabled}
          itemsPerRow={formFieldDefinition.itemsPerRow}
        />
      </FormControl>
    );
  }

  const fieldPlaceholder =
    formFieldDefinition.placeholder ??
    getDefaultPlaceholderFallback(formFieldDefinition.type) ??
    (formFieldDefinition.required ? "(Required)" : "(Optional)");

  if (formFieldDefinition.type === "select") {
    return (
      <Select
        onValueChange={formField.onChange}
        value={formField.value}
        disabled={formFieldDefinition.readonly ? true : formField.disabled}
      >
        <FormControl>
          <SelectTrigger
            className={cn(
              "focus:border-primary h-[42px] bg-white text-base text-gray-900 focus:ring-0 focus:ring-transparent data-[placeholder]:text-gray-400 dark:text-current",
              error && "border-red-300",
            )}
          >
            {formField.value === undefined ||
            formField.value === null ||
            formField.value === "" ? (
              <span className="text-muted-foreground pointer-events-none">
                {fieldPlaceholder ?? "Choose an option"}
              </span>
            ) : (
              <SelectValue />
            )}
          </SelectTrigger>
        </FormControl>
        <SelectContent className="max-h-56">
          {formFieldDefinition.options.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
              className="my-1 text-base text-gray-700"
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }

  if (formFieldDefinition.type === "date") {
    const { value: date, onChange: onDateChange } = formField;

    return (
      <FormControl>
        <DatePicker
          date={date}
          onDateChange={onDateChange}
          placeholder={fieldPlaceholder}
          disabled={formFieldDefinition.readonly ? true : formField.disabled}
          classes={{ trigger: error && "border-red-300" }}
        />
      </FormControl>
    );
  }

  if (formFieldDefinition.type === "birthday") {
    const { value: date, onChange: onDateChange } = formField;

    let birthdayProps = {
      fromYear: new Date().getFullYear() - 100,
      toYear: new Date().getFullYear(),
      disabled: (date: Date) =>
        date > new Date() || date < new Date("1900-01-01"),
    };

    if (formFieldDefinition.range) {
      const [start, end] = formFieldDefinition.range;

      const ageToDate = (age: number) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return new Date(
          currentYear - age,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
      };

      birthdayProps = {
        fromYear: ageToDate(end).getFullYear(),
        toYear: ageToDate(start).getFullYear(),
        disabled: (date: Date) =>
          date < ageToDate(end) || date > ageToDate(start),
      };
    }

    return (
      <FormControl>
        <DatePicker
          date={date}
          onDateChange={onDateChange}
          placeholder={fieldPlaceholder}
          disabled={formFieldDefinition.readonly ? true : formField.disabled}
          classes={{ trigger: error && "border-red-300" }}
          dayPickerProps={birthdayProps}
        />
      </FormControl>
    );
  }

  if (formFieldDefinition.type === "tag") {
    return (
      <FormControl>
        <TagSelect
          options={formFieldDefinition.options}
          onChange={formField.onChange}
          value={formField.value}
          placeholder={fieldPlaceholder ?? "Select an option"}
          isCreatable={formFieldDefinition.isCreatable}
          onSearch={formFieldDefinition.onSearch}
          maxLength={formFieldDefinition.maxLength}
        />
      </FormControl>
    );
  }

  const textInputOnChange =
    formFieldDefinition.type === "text"
      ? (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const value = e.target.value;

          const { countingMethod = "normal", maxLength } = formFieldDefinition;

          if (maxLength && getLengthByMethod[countingMethod](value) > maxLength)
            return;

          formField.onChange(value);
        }
      : formField.onChange;

  if (
    formFieldDefinition.type === "text" &&
    (formFieldDefinition.rows ?? 0) > 0
  ) {
    return (
      <FormControl>
        <FormTextarea
          placeholder={fieldPlaceholder}
          rows={formFieldDefinition.rows}
          {...formField}
          onChange={textInputOnChange}
          className={cn(
            "focus-visible:border-primary text-base text-gray-900 focus-visible:ring-0 focus-visible:ring-transparent dark:text-current",
            error && "border-red-300 focus-visible:border-red-300",
            formFieldDefinition.readonly &&
              "border-gray-300 bg-gray-100 text-gray-900 disabled:opacity-100 dark:text-current",
          )}
          disabled={formFieldDefinition.readonly ? true : formField.disabled}
        />
      </FormControl>
    );
  }

  const getHtmlInputType = () => {
    switch (formFieldDefinition.type) {
      case "password":
        return "password";
      case "email":
        return "email";
      case "phone":
        return "tel";
      case "url":
        return "url";
      default:
        return "text";
    }
  };

  /** TODO: use it later */
  // const getHtmlInputMode = () => {
  //   switch (formFieldDefinition.type) {
  //     case 'email':
  //       return 'email';
  //     case 'phone':
  //       return 'tel';
  //     case 'url':
  //       return 'url';
  //     default:
  //       return undefined;
  //   }
  // };

  const hasInputIcon = "icon" in formFieldDefinition;
  const inputElement = (
    <FormInput
      type={getHtmlInputType()}
      placeholder={fieldPlaceholder}
      {...formField}
      onChange={textInputOnChange}
      className={cn(
        "focus-visible:border-primary text-base text-gray-900 focus-visible:ring-0 focus-visible:ring-transparent dark:text-current",
        error && "border-red-300 focus-visible:border-red-300",
        formFieldDefinition.readonly &&
          "border-gray-300 bg-gray-100 text-gray-900 disabled:opacity-100 dark:text-current",
        !hasInputIcon && "px-3",
      )}
      disabled={formFieldDefinition.readonly ? true : formField.disabled}
    />
  );

  if (hasInputIcon) {
    return (
      <FormControl>
        <InputGroup>
          <InputItem.Left masked={formFieldDefinition.iconMasked}>
            {formFieldDefinition.icon}
          </InputItem.Left>
          <InputControl.Right masked={formFieldDefinition.iconMasked}>
            {inputElement}
          </InputControl.Right>
        </InputGroup>
      </FormControl>
    );
  }

  return <FormControl>{inputElement}</FormControl>;
};
