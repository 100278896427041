import { useUserContext } from "@/oldFeatures/auth/hooks";
import { trpc } from "@/utils/trpc";
import { useEffect } from "react";

export const usePrefetchAppData = () => {
  const utils = trpc.useUtils();
  const { user } = useUserContext();

  useEffect(() => {
    if (!user) return;

    void utils.gift.gifts.prefetch();
    void utils.edgeConfig.projectConfig.prefetch();
  }, [user, utils.edgeConfig.projectConfig, utils.gift.gifts]);
};
