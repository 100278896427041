import * as React from "react";
import { Input as ShadcnInput } from "./shadcn/input";
import { cn } from ".";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <ShadcnInput
        ref={ref}
        {...props}
        className={cn(
          "focus-visible:border-primary h-auto text-base text-gray-900 focus-visible:ring-0 focus-visible:ring-transparent",
          className,
        )}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
