import { UserDropdownMenu } from "@/featuresPrincess/layout/userMenu/UserDropdownMenu";
import { cn } from "@/helpers/cn";
import Link from "next/link";
import { NavContainer } from "@/featuresPrincess/layout/NavContainer";
import { useMainNavigationItems } from "../common/hooks/useMainNavigationItems";
import { useUserNavigationItems } from "../user/hooks/useUserNavigationItems";
import { NotificationPopover } from "@/features/notification/components/NotificationPopover";

export const UserHeader = () => {
  const mainNavigationItems = useMainNavigationItems();
  const userNavigationitems = useUserNavigationItems();

  return (
    <NavContainer>
      {/* Icon links */}
      <div className="hidden items-center justify-end gap-6 text-sm md:flex md:flex-1">
        {mainNavigationItems.map((item) => (
          <Link
            key={item.title}
            href={item.href!}
            passHref
            className="relative block overflow-visible"
          >
            <div
              className={cn(
                "hover:text-primary-500 flex items-center gap-2 px-3 py-2 text-base font-normal",
                item.isActive ? "text-primary-500" : "text-gray-700",
              )}
            >
              <item.icon className="h-6 w-6" />
              <span className="w-max">{item.title}</span>
            </div>
            {!!item.badge && (
              <div className="bg-primary-500 absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full text-xs text-white">
                {item.badge}
              </div>
            )}
          </Link>
        ))}

        <NotificationPopover />

        <div className="h-5 w-[1px] bg-gray-200"></div>

        <UserDropdownMenu menuItems={userNavigationitems} />
      </div>

      <div className="contents md:hidden">
        <NotificationPopover />
      </div>
    </NavContainer>
  );
};
